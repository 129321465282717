import acorns from "../../assets/images/acorns.jpg";
import ObitEditForm from "../../components/forms/obitEditForm";
import Guarantee from "../../components/guarantee";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { SERVER_URL, STRAPI_API_TOKEN } from "../../constants";
import React, { useEffect, useState } from "react";
import { NumberParam, useQueryParam } from "use-query-params";

const ObitEditor = ({ location }) => {
  const [id, setId] = useQueryParam("id", NumberParam);
  const [informantEmail, setInformantEmail] = useState("");
  const [obitError, setObitError] = useState(false);
  const [currentName, setCurrentName] = useState("");
  const [currentObit, setCurrentObit] = useState("");

  useEffect(() => {
    setId(new URLSearchParams(location.search).get("id"));
    console.log("fired");
  }, [location.search, setId]);

  useEffect(() => {
    fetch(`${SERVER_URL}/api/articles/${id}`, {
      // headers: {
      //   Authorization: `Bearer ${STRAPI_API_TOKEN}`,
      // },
    })
      .then((res) => res.json())
      .then(({ data: { attributes: data } }) => {
        console.log(data);
        // if (!data) {
        //   fetch(
        //     `${SERVER_URL}/articles?id=${id}&_publicationState=preview&published_at_null=true`
        //   )
        //     .then((res) => res.json())
        //     .then(([data]) => {
        if (!data) {
          setObitError(true);
        } else {
          setInformantEmail(data.informantEmail);
          setCurrentName(data.name);
          setCurrentObit(data.content);
        }
        //     });
        // } else {
        //   setInformantEmail(data.informantEmail);
        //   setCurrentName(data.name);
        //   setCurrentObit(data.content);
        // }
      });
    // });
  }, []);

  return (
    <Layout hasHero={false} heroSize="30rem" heroImage={acorns}>
      <SEO
        title="Update an Obituary | Tell Their Story"
        description="Use our custom form to update the obituary of a loved one with their picture and a celebration of their life, then you can share it with friends and family, on Facebook or anywher else."
      />
      {id && !obitError ? (
        <>
          <div className="min-h-screen mb-6 flex flex-col items-start">
            <div className="w-full mt-10 font-serif font-hairline">
              <h1 className="text-4xl md:text-5xl text-green-700">
                Tell the world a little more about your loved one.
              </h1>
            </div>
            <div>
              <p className="text-xl py-2">
                Tell their story below and add a picture if you'd like and we'll
                add it to the obituary honoring their life.
              </p>
            </div>{" "}
            {/* <p className='text-xl py-2'>
          Our staff has answers to your questions. We promise to make this
          process bearable and affordable and we are committed to doing it with
          the utmost professionalism.
        </p> */}
            <div
              className="w-full sm:w-3/4 lg:w-1/2 my-4 px-6 py-4 self-center"
              style={{
                boxShadow:
                  "0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07)",
              }}
            >
              <p className="text-3xl text-center">
                <strong>
                  {" "}
                  Obituary{currentName && ` for ${currentName}`}:{" "}
                </strong>
              </p>

              <ObitEditForm
                informantEmail={informantEmail}
                id={id}
                currentName={currentName}
                currentObit={currentObit}
                setCurrentObit={setCurrentObit}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="min-h-screen mb-6 flex flex-col items-start">
            <div className="w-full mt-10 font-serif font-hairline">
              <h1 className="text-4xl md:text-5xl text-green-700">
                Sorry. There's been some mistake.
              </h1>
            </div>
            <div>
              <p className="text-xl py-2">
                If you received a link to update an obituary, go back and double
                check the link to make sure it matches the one in the address
                bar.
              </p>
            </div>{" "}
          </div>
        </>
      )}
      <Guarantee />
    </Layout>
  );
};

export default ObitEditor;
