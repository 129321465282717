import {
  SERVER_URL,
  STRAPI_USER_EMAIL,
  STRAPI_USER_PASSWORD,
} from "./constants";

const https = require("http");

export function authorizeStrapi() {
  return new Promise((resolve, reject) => {
    fetch(`${SERVER_URL}/auth/local`, {
      headers: { "Content-Type": "application/json; charset=utf-8" },
      method: "POST",
      body: JSON.stringify({
        identifier: STRAPI_USER_EMAIL,
        password: STRAPI_USER_PASSWORD,
      }),
    }).then((res) => res.json().then((body) => resolve({ token: body.jwt })));
  });
}

export function updateStrapiPost({ id, data, token }) {
  console.log(data);
  return new Promise((resolve, reject) => {
    fetch(`${SERVER_URL}/api/articles/${id}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${token}`,
      },
      method: "PUT",
      body: JSON.stringify({ data }),
    })
      .then((res) =>
        res
          .json()
          .then((body) => resolve({ statusCode: res.status, response: body }))
      )
      .catch((err) => reject(err));
  });
}

export function uploadStrapiPhoto({ data, token }) {
  console.log(data);
  return new Promise((resolve, reject) => {
    fetch(`${SERVER_URL}/api/upload`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "POST",
      body: data,
    })
      .then((res) =>
        res
          .json()
          .then((body) => resolve({ statusCode: res.status, response: body }))
      )
      .catch((err) => reject(err));
  });
}
