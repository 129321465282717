import { STRAPI_API_TOKEN } from "../../constants";
import {
  authorizeStrapi,
  updateStrapiPost,
  uploadStrapiPhoto,
} from "../../strapi";
import "./form.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { navigate } from "@reach/router";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

const ObitEditForm = ({
  informantEmail,
  id,
  currentName,
  currentObit,
  setCurrentObit,
}) => {
  const [textLoading, setTextLoading] = useState(false);
  const [photoLoading, setPhotoLoading] = useState(false);
  const [textStatus, setTextStatus] = useState(null);
  const [photoStatus, setPhotoStatus] = useState(null);

  useEffect(() => {
    console.log(
      ":::textLoading",
      textLoading,
      ":::photoLoading",
      photoLoading,
      ":::textStatus",
      textStatus,
      ":::photoStatus",
      photoStatus
    );
    if (!textLoading && !photoLoading && (textStatus || photoStatus)) {
      console.log("useEffect", "text", textStatus, "photo", photoStatus);
      if (photoStatus && +photoStatus !== 200) {
        if (textStatus && textStatus !== 200) {
          alert(
            "Something went wrong updating your obituary. We're sorry, please refresh the page and try again and if that doesn't work let us know."
          );
        } else {
          alert(
            "There was an issue with the image you uploaded. It may have been too large—images over 200MB cannot be used. Please modify the image or try again with a different image."
          );
        }
      } else if (textStatus && textStatus !== 200) {
        alert(
          "Something went wrong updating your obituary. We're sorry, please refresh the page and try again and if that doesn't work let us know."
        );
      } else {
        navigate("/success/", {
          state: {
            message: `Thank you. The updated information will be displayed soon.`,
          },
        });
      }
    }
  }, [textLoading, photoLoading, textStatus, photoStatus]);

  console.log(process.env.GATSBY_STRAPI_USER_EMAIL);

  const schema = Yup.object().shape({
    "informant-email": Yup.string()
      .email("Please provide a valid email address.")
      .required("Email is required")
      .matches(informantEmail, "Email doesn't match"),
    obituary: Yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (submission) => {
    // gtag_report_conversion(window.location);
    const {
      "display-name": displayName = "",
      obituary = "",
      picture: image,
    } = submission;

    const updateObj = {
      userUpdated: true,
      // this field changd to snake case user_updated with strapi V4
      user_updated: true,
      published_at: null,
      // meanwhile this field changed to camelCase updatedAt with strapi V4
      // leaving the old properties for now just in case
      publishedAt: null,
      ...(displayName && { name: displayName.trim() }),
      ...(obituary && { content: obituary.trim() }),
    };

    const photoObj = new FormData();
    if (image[0]?.name) {
      photoObj.append("files", image[0], {
        filename: image[0].name,
      });
      photoObj.append("ref", "api::article.article");
      photoObj.append("refId", id);
      photoObj.append("field", "image");
    }

    // const { token } = await authorizeStrapi();

    if (displayName || obituary) {
      setTextLoading(true);
      try {
        const res = await updateStrapiPost({
          id,
          // token,
          token: STRAPI_API_TOKEN,
          data: updateObj,
        });
        setTextLoading(false);
        setTextStatus(res.statusCode);
      } catch (err) {
        setTextLoading(false);
        setTextStatus(500);
      }
    }

    if (image[0]?.name) {
      setPhotoLoading(true);
      try {
        const res = await uploadStrapiPhoto({
          // token,
          token: STRAPI_API_TOKEN,
          data: photoObj,
        });
        console.log(":::photoRes", res);
        setPhotoLoading(false);
        setPhotoStatus(res.statusCode);
      } catch (err) {
        console.log(":::photoErr", err);
        setPhotoLoading(false);
        setPhotoStatus(500);
      }
    }
  };

  return (
    <form className="form-input" onSubmit={handleSubmit(onSubmit)}>
      <label className="block mt-3">
        <span className="text-gray-700">Type obituary here:</span>
        <textarea
          className="form-textarea mt-1 mb-4 block w-full"
          {...register("obituary")}
          rows="8"
          value={currentObit}
          onChange={(e) => setCurrentObit(e.currentTarget.value)}
        />
        {errors.obituary && (
          <div className="error-msg">{errors.obituary.message}</div>
        )}
      </label>

      <div className="block">
        <label for="display-name" className="text-gray-700">
          Full display name:
          <span className="tooltip">
            <span className="tooltip-text">(preferred full name)</span>
          </span>
        </label>
      </div>
      <div className="block">
        <input
          type="text"
          className="form-input decedent-form block w-full"
          {...register("display-name")}
          access="false"
          id="display-name"
          placeholder={currentName}
        />
      </div>
      {errors["display-name"] && (
        <div className="error-msg">{errors["display-name"].message}</div>
      )}
      <div className="text-gray-700 text-sm mt-1 ml-1">
        This is the full name that will show at the top of the obituary. If your
        loved one went by a shortened name or nickname, you can use it here, or
        if they used a suffix like 'Jr.'
        <br />
        {currentName && (
          <span>
            We're currently using the name <strong>{currentName}</strong>. If
            you're happy with that, don't enter anything in this field.
          </span>
        )}
      </div>

      <label className="mt-4 block">
        <span className="text-gray-700">Upload a picture:</span>
        <br />
        <input type="file" {...register("picture")} accept="image/*" />
        {errors["picture"] && (
          <div className="error-msg">{errors["picture"].message}</div>
        )}
      </label>

      <label className="block mt-4">
        <span className="text-gray-700">Informant's email address:</span>
        <input
          type="text"
          className="form-input mt-1 block w-full"
          placeholder="yourname@email.com"
          {...register("informant-email")}
        />
        {errors["informant-email"] && (
          <div className="error-msg">{errors["informant-email"].message}</div>
        )}
        <div className="text-gray-700 text-sm mt-1 ml-1">
          For security purposes, please enter the email that was provided along
          with the deceased's vital statistical information. This is the same
          email address this upload link was sent to.
        </div>
      </label>
      <input
        className="mt-4 py-2 px-4 bg-green-700 hover:bg-green-600 text-base text-white font-bold uppercase rounded shadow-md hover:-translate-1 cursor-pointer"
        type="submit"
        value="Submit"
      />
      <div className="text-gray-700 text-sm mt-3 ml-1">
        <strong>Caution:</strong> Any data submitted here will{" "}
        <strong>replace</strong> data in the existing obituary. Any fields left
        blank will not effect the existing obituary.
        <br />
        Updates made here will not be instantaneous. Changes may take up to
        several days.
      </div>
    </form>
  );
};

export default ObitEditForm;
